body {
  background-color: none !important;
  /* background: none !important;
    background-image: url('../src//assests/screenartwork1.png') !important;
    background-size: cover !important;  */
}

.slick-prev:before {
  font-size: 26px !important;
  color: grey;
}

.slick-next:before {
  font-size: 26px !important;
  color: grey;
}

.rbc-header {
  text-align: center !important;
}

.rbc-timeslot-group {
  min-height: unset !important;
}

.css-cy89nf {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.css-dw4mji {
  padding-bottom: 8px !important;
}
.css-iulusl {
  margin: 20px !important;
}
.chakra-popover__popper {
  color: black;
}

@media screen and (min-width: 426px) {
  .css-g2yh9z {
    /* margin-left: -100px !important; */
  }
  .css-wl0d9u {
    left: 8% !important;
  }

  .chakra-toast {
    width: calc(100% - 280px) !important;
    margin-left: auto;
  }
}
.euro-sign {
  font-weight: 800;
  color: #0033ff;
}

.chakra-switch__input .chakra-switch__track css-p27qcy {
  background-color: #0033ff !important;
}
.css-7roig {
  height: 40px !important;
  width: 70px !important ;
}
.chakra-ui-dark .css-p27qcy:not([data-theme]),
[data-theme='dark'] .css-p27qcy:not([data-theme]),
.css-p27qcy[data-theme='dark'] {
  border-radius: 5px;
  --switch-bg: #0033ff !important;
  width: 115px;
  height: 40px;
  padding: 0 !important;
  border: none;
}

#root
  > section
  > div
  > main
  > div.css-15piw3q
  > div
  > form
  > div.chakra-stack.css-11rawo4
  > div:nth-child(1)
  > label.chakra-switch.css-6skkj7
  > span {
  --switch-bg: none !important;
  width: 113px;
  height: 30px;
  border-radius: 14px;
}

#root
  > section
  > div
  > main
  > div.css-15piw3q
  > div
  > form
  > div.chakra-stack.css-11rawo4
  > div:nth-child(1)
  > label.chakra-switch.css-6skkj7
  > span
  > span {
  width: 55px;
  height: 30px;
}
.css-6skkj7 {
  --switch-track-width: 4.875rem !important;
}

.alice-carousel__stage-item {
  width: 320px !important;
  margin-right: 5px;
}

.css-dnkt4r:focus-visible {
  border-color: #fff;
}
