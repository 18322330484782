Main Calendar Container .rbc-calendar {
}

/* Toolbar */
.rbc-toolbar {
  display: flex;
  justify-content: space-between;
}
.rbc-toolbar-label {
}
.rbc-btn-group {
  border-radius: 20px;
}
.rbc-toolbar button {
  border: none;
  border-radius: 20px;
}

/* Navigation Buttons */
.rbc-btn-group button {
}

/* Month View */
.rbc-month-view {
  border: none;
}
.rbc-month-header {
}

.rbc-month-row {
}

.rbc-header {
  padding: 20px 0px;
  text-align: end;
  border-left: none;
  border-right: none;
  border-bottom: none;
}

.rbc-header + .rbc-header {
  border-left: none;
}

/* Week and Day Views */
.rbc-time-view {
}
.rbc-time-header {
}
.rbc-time-header-gutter {
}
.rbc-time-header-content {
}
.rbc-time-content {
}

/* Agenda View */
.rbc-agenda-view {
}
.rbc-agenda-table {
}
.rbc-agenda-time-cell {
}
.rbc-agenda-event-cell {
}
.rbc-agenda-date-cell {
}
.rbc-agenda-content {
}

/* Events */

.rbc-event-content {
  /* height: 100%; */
  text-align: center;
}
.rbc-event-content {
}
.rbc-event-label {
}
.rbc-event-overlaps {
}

/* Today */
.rbc-today {
}

/* Selected */
.rbc-selected {
}

/* Off-Range Days */
.rbc-off-range {
}
.rbc-off-range-bg {
}

/* Event Overlapping */
.rbc-event-allday {
}
.rbc-event-continues-prior {
}
.rbc-event-continues-after {
}

.rbc-today {
  background-color: inherit;
}

.rbc-allday-cell {
  display: none;
}

.rbc-timeslot-group {
  min-height: 100px;
}

.rbc-current-time-indicator {
  display: none;
}

.rbc-day-slot .rbc-time-slot {
  border-top: none;
}

.rbc-calendar .rbc-month-view .rbc-event {
  max-height: 3rem !important;
  width: 90% !important;
  max-height: 100% !important;
  background-color: #4d4d4d !important;
  box-sizing: border-box;
  border-radius: 10px !important;
  border: 3px solid #0033ff !important;
  /* display: flex !important; */
  justify-items: center !important;
  align-items: center !important;
  justify-content: space-between !important;
}

/* If you want to adjust padding and ensure the content fits */
.rbc-calendar .rbc-month-view .rbc-event .rbc-event-content {
  padding: 0 !important; /* Adjust padding based on your design */
  font-size: 0.75em;
  /* Smaller font size if needed */
  white-space: normal; /* Allow text to wrap */
}

.rbc-row-content {
  height: 4rem;
  display: flex;
  flex-direction: column;
}
.rbc-day-bg {
  background: linear-gradient(to bottom right, #282828, #131313) !important;
  padding-left: 2rem !important;
  padding-bottom: 1rem !important;
}
.rbc-row-content :nth-child(1) {
  font-size: 1.3rem;
  font-weight: bolder;
  padding-right: 1rem;
}

.rbc-row-content :nth-child(2) {
  font-size: 1.2rem !important;
  font-weight: bold !important;
}

@media (max-width: 1400px) {
  .rbc-row-segment {
    padding: 5px 5px !important;
  }
  .rbc-row-content :nth-child(2) {
  }
  .rbc-row-content :nth-child(1) {
    /* flex: 1; */
    padding-right: 0px;
  }
  .rbc-row-content :nth-child(1) {
    padding-right: 0px;
  }
}

.rbc-calendar .rbc-month-view .rbc-event {
  height: auto !important;
}

.css-i5q2k0 {
  width: 100%;
}

.rbc-month-header {
  border-radius: 10px !important;
  margin: 2px 2px 0 2px;
}

.rbc-header {
  background-color: #000;
  font-size: 1.2rem;
  font-weight: bold;
}

.rbc-date-cell {
  font-size: 24px;
}

.rbc-month-row {
  padding-top: 20px;
  padding-bottom: 100px;
  border: none !important;
}
.rbc-row-bg {
  background: linear-gradient(to right, #252525, grey, grey);
}
